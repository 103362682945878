@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
    --primary-color: #001D37;
    --secondary-color: #FFCB52;
    --third-color: #383838;
    --fourth-color:#001D37;
    --fifth-color:#FDD106;
}
.common-blue
{
    color: #001D37;
}
.common-yellow{
    color: var(--fifth-color);
}
.common-bg-yellow{
    background-color: var(--fifth-color);
}
.common-bg-blue{
    background-color: #001D37;
}
:focus-visible
{
    outline: none;
}
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-family: "Roboto", sans-serif;
}

h1 {
    font-weight: 700;
    font-size: 50px;
}

h2 {
    font-weight: 700;
    font-size: 30px;
}

h4 {
    font-weight: 700;
    font-size: 20px;
}

h5 {
    font-weight: 400;
    font-size: 18px;
}

p {
    color: var(--third-color);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

a {
    text-decoration: none;
}

ul{
    list-style: none;
    padding-left: 0;
}

/* Common Button */

.commonButton{
    border: 1px solid var(--secondary-color);
    padding: 10px 30px;
    border-radius: 6px;
    color: var(--third-color);
    font-size: 14px;
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
    transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
}

.commonButton:before,
.commonButton:after {
    content: '';
    position: absolute;
    transition: inherit;
    z-index: -1;
}

.commonButton:hover {
    transition-delay: .5s;
    color: var(--third-color);
}

.commonButton:hover:before {
    transition-delay: 0s;
}

.commonButton:hover:after {
    background-color: var(--secondary-color);
    transition-delay: .35s;
}

.commonButton:before,
.commonButton:after {
    top: 0;
    width: 0;
    height: 100%;
}

.commonButton:after {
    left: 0;
}

.commonButton:hover:before,
.commonButton:hover:after {
    width: 100%;
}

/* Second Common Button */

.commonButtonSecond {
    color: var(--third-color);
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

.commonButtonSecond span{
    transition: all .3s ease-in-out;;
}

.commonButtonSecond:hover{
    color: var(--third-color);
}

.commonButtonSecond:hover span{
    padding-right: 10px;
}

.commonLoadAll
{
    background-color: #FDD106;
    padding: 4px 35px;
    cursor: pointer;
    font-size: 13px;
    font-family: Roboto;
   font-weight: 700;
   line-height: 23px;
   color: var(--fourth-color);
}

.commonLoadAll:hover,.commonLoadAll:first-child:active{
    color: var(--fourth-color)!important;
    background-color: #ffe361!important;
}

.btn-check:checked+.btn, .btn.active, .btn.show, .btn:first-child:active, :not(.btn-check)+.btn:active
{
    color: var(--fourth-color)!important;
    background-color: #ffe361!important;
    border-color:#ffe361;
}

.navbar-toggler

{
    background-color: #FDD106;
    border:0;
    border-radius: 0;
    padding: 4px;
}
.navbar-toggler svg{
    color:#001D37;
    
}

.navbar-toggler:focus
{
    box-shadow: none;
}
.navbar-toggler-icon{
    background-image: none;
    display: flex;
    align-items: center;
    justify-content: center;

}


.slick-initialized .slick-slide
{
    text-align: center;
}

.common-cursor-pointer{
    cursor: pointer;
}

/* Whatsapp Icon */

.whatsapp-share-img{
    position: fixed;
    right: 180px;
    bottom: 15px;
    z-index: 99;
    display: block!important;
}

.whatsapp-share-img img{
    height: 70px!important;
}

@media (max-width: 575.98px) {
    .whatsapp-share-img {
        left: 20px;
    }
}

@media (max-width: 767.98px) {
    .whatsapp-share-img {
        right: 143px;
    }
}

@media (max-width: 991.98px) {
    .whatsapp-share-img {

        right: 150px;
    }
}

@media (max-width: 575px) {
    .whatsapp-share-img {
     display: none;
    }
    .whatsapp-share-img img{
 display: none;    }  
}